import Button from "@/components/Button/button";
import Col from "@/components/Col/Col";
import CtaBar from "@/components/CtaBar/CtaBar";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Section from "@/components/Section/Section";
import Title from "@/components/Title/Title";
import supportedAds from "@/img/ads.svg";
import supportedCashflow from "@/img/cashflow.svg";
import supportedGoogle from "@/img/googleads.svg";
import integrationTheme from "@/img/integrationTheme.svg";
import supportedPlatforms from "@/img/platforms.png";
import supportedShopify from "@/img/shopify.svg";
import supportedShoptet from "@/img/shoptet.svg";
import supportedYoutube from "@/img/youtube.svg";
import supportedInstagram from "@/img/instagram.svg";
import supportedWoo from "@/img/woo.svg";
import { Link } from "gatsby";
import * as React from "react";

const Integration = () => (
  <main className="page__integration">
    <Header isIntegration></Header>

    <Section colFlex vCentered className="hero">
      <Col size="50" className="hero__headline">
        <h1>Ready to rock on many platforms!</h1>
        <p className="testimonial__logos__description">
          Just follow our copy & paste solution, that immediately starts
          tracking your data. Currently we support full Shopify, WooCommerce and
          Facebook integration.
        </p>

        <div className="testimonial__logos__buttons">
          <Button
            {...{
              variant: "blue",
              link: "https://app.ecomtrack.io/register",
              text: "Start Tracking",
            }}
          />

          <Button
            {...{
              variant: "transparent",
              link: "/walkthrough",
              text: "Explore integration",
            }}
          />
        </div>
      </Col>

      <Col size="50" className="">
        <img src={supportedPlatforms} className="" />
      </Col>
    </Section>

    <Section colFlex vCentered>
      <Col size="50">
        <Title
          subtitle="Let’s kick it off!"
          maintitle="Easy to set up and use."></Title>
        <p>
          Just copy&paste solution that starts working immediately once the code
          is inside your website.
        </p>
        <p className="bulletCheck">Takes less than 5 minutes</p>
        <p className="bulletCheck">No advanced coding skills</p>
        <p className="bulletCheck">Accurate data within days</p>
      </Col>
      <Col size="50">
        <img src={integrationTheme} />
      </Col>
    </Section>

    <Section colFlex vCentered className="Supported">
      <Col size="50">
        <Title
          maintitle="Supported platforms"
          subtitle="Connecting ads with stores"
        />

        <p>
          Trying to bring you the best experience possible we are continously
          upgrading our base of supported platforms.
        </p>

        <p>
          Your platform is still not here? Don't hesitate and{" "}
          <Link className="baseLink" to="/contact/">
            let us know.
          </Link>
        </p>
      </Col>

      <Col size="50" className="Supported__wrap">
        <div className="Supported__tile">
          <img src={supportedWoo} />
          <div>
            <h5 className="Supported__tile__text">WooCommerce</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedAds} />
          <div>
            <h5 className="Supported__tile__text">Facebook</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedShopify} />
          <div>
            <h5 className="Supported__tile__text">Shopify</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedShoptet} />
          <div>
            <h5 className="Supported__tile__text">Shoptet</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedCashflow} />
          <div>
            <h5 className="Supported__tile__text">Mycashflow</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedGoogle} />
          <div>
            <h5 className="Supported__tile__text">Google Ads</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedYoutube} />
          <div>
            <h5 className="Supported__tile__text">YouTube</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>

        <div className="Supported__tile">
          <img src={supportedInstagram} />
          <div>
            <h5 className="Supported__tile__text">Instagram</h5>
            <p className="Supported__tile__subtext">Supported</p>
          </div>
        </div>
      </Col>
    </Section>

    <Section className="CtaWrapper ">
      <CtaBar
        title="Ready to start tracking?"
        buttonLink="https://app.ecomtrack.io/register"
        buttonText="Start Tracking"
      />
    </Section>

    <Footer colFlex></Footer>
  </main>
);
export default Integration;
